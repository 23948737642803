@import '~normalize.css/normalize.css';

@import 'base/font';
@import 'base/variables';
@import 'base/base';
@import 'base/rotation_animation';

.customize-react-image-lightbox {
  .ril-toolbar__item:nth-child(2) {
    float: left;
  }

  .ril__image {
    background: white;
  }
}
