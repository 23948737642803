body {
  height: 100%;
}

#main_container {
  font: 12px $primary-font-family;
}

.main-container {
  padding-top: 68px; 
}

table {
  border-spacing: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

// For remove plcae order pick-up & drop-off dnd block outline style.
:focus-visible {
  outline: none;
}
